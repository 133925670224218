@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@tailwind base;
html{
    scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;
*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Outfit';

}